import { BoxProps, HeadingProps, IconButtonProps, ButtonProps } from '@chakra-ui/react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import React from 'react'

export const boxProps: BoxProps = {
    p: 6,
    maxWidth: '400px',
    margin: 'auto',
    mt: '10%'
}

export const headingProps: HeadingProps = {
    as: 'h1',
    size: 'lg',
    color: 'red.600',
    fontWeight: 'bold',
    fontFamily: 'Creepster, sans-serif',
    cursor: 'pointer'
}

export const iconButtonProps = (showPassword: boolean): IconButtonProps => ({
    'aria-label': `${showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}`,
    icon: showPassword ? React.createElement(FaEyeSlash) : React.createElement(FaEye),
    variant: 'ghost',
    size: 'sm',
    color: 'gray.500'
})

export const buttonProps: ButtonProps = {
    type: 'submit',
    colorScheme: 'red',
    width: 'full',
    bg: 'red.500',
    _hover: { bg: 'red.600' }
}
