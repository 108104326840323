import {
    CenterProps,
    HeadingProps,
    IconButtonProps,
    IconProps,
    ImageProps,
    ResponsiveValue,
    TextProps
} from '@chakra-ui/react';

export const boxProps = {
    bg: 'gray.900',
    minH: '100vh',
    position: 'relative' as ResponsiveValue<'relative'>,
    p: [1, 4]
};

export const headingProps: HeadingProps = {
    as: 'h2',
    size: 'lg',
    color: 'white',
    mb: 3,
    textAlign: 'center',
    borderRadius: 'md',
    p: [1, 0]
};

export const headingTextProps: TextProps = {
    as: 'span',
    color: 'red.400'
};

export const gridImageProps: ImageProps = {
    loading: 'lazy',
    maxHeight: ['200px', '260px', '320px'],
    width: ['170px', '200px', '240px'],
    height: ['180px', '260px', '320px'],
    borderRadius: ['10px', '20px'],
    objectFit: 'cover'
};

export const centerNotFoundProps: CenterProps = {
    flexDirection: 'column',
    minH: '60vh',
    color: 'gray.500'
};

export const iconNotFoundProps: IconProps = {
    boxSize: '8',
    color: 'red.400',
    mb: 4
};

export const iconScrollToTopProps: IconButtonProps = {
    'aria-label': 'Scroll to top',
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    colorScheme: 'red',
    borderRadius: 'full',
    size: 'lg',
    boxShadow: 'lg'
};
