import { FaRandom } from 'react-icons/fa'
import {
    ButtonProps,
    MenuListProps,
    BoxProps,
    FlexProps,
    HeadingProps,
    IconButtonProps,
    TextProps
} from '@chakra-ui/react'
import React from 'react'

export const boxProps: BoxProps = {
    bg: '#0A0A0A',
    w: '100%',
    p: 3,
    position: 'sticky',
    top: '0',
    zIndex: '1000',
    boxShadow: 'md',
    transition: 'all 0.3s ease',
    maxW: '100vw'
}

export const flexProps: FlexProps = {
    align: 'center',
    justify: 'space-between',
    maxW: '95%',
    mx: 'auto'
}

export const flexLogoProps: FlexProps = {
    flex: '1',
    align: 'center',
    justify: { base: 'center', md: 'flex-start' },
    position: 'relative'
}

export const flexHamburguerProps: FlexProps = {
    display: { base: 'flex', md: 'none' },
    ml: 'auto'
}

export const flexMenuAndSearchProps: FlexProps = {
    display: { base: 'none', md: 'flex' },
    align: 'center',
    gap: 4,
    flex: '1',
    justify: 'flex-start',
    ml: '1rem'
}

export const flexMenuRightOptionsProps: FlexProps = {
    align: 'center',
    gap: 2,
    ml: 'auto'
}

export const linkFlexProps: FlexProps = {
    align: 'center',
    gap: 6
}

export const headingProps: HeadingProps = {
    as: 'h1',
    size: 'lg',
    color: 'red.600',
    fontWeight: 'bold',
    fontFamily: 'Creepster, sans-serif',
    cursor: 'pointer',
    margin: 0
}

export const iconButtonProps: IconButtonProps = {
    color: 'white',
    bg: 'transparent',
    _hover: { color: 'red.400' },
    fontSize: 'lg',
    'aria-label': ''
}

export const menuButtonProps: ButtonProps = {
    color: 'white',
    fontSize: 'md',
    bg: 'transparent',
    minW: 'max-content',
    _hover: { bg: 'gray.700', color: 'red.400' },
    _expanded: { bg: 'gray.700', color: 'red.500' },
    _focus: { boxShadow: 'none' }
}

export const movieButtonProps: ButtonProps = {
    color: 'white',
    fontSize: 'md',
    variant: 'ghost',
    _hover: { bg: 'gray.700', color: 'red.400' },
    _expanded: { bg: 'gray.700', color: 'red.500' }
}

export const seriesButtonProps: ButtonProps = {
    color: 'white',
    fontSize: 'md',
    variant: 'ghost',
    _hover: { bg: 'gray.700', color: 'red.400' },
    _expanded: { bg: 'gray.700', color: 'red.500' },
    mr: '2rem'
}

export const shuffleButtonProps = (isIconActive: boolean) => ({
    'aria-label': 'Shuffle Movies',
    bg: 'transparent',
    icon: React.createElement(FaRandom),
    color: isIconActive ? 'red.400' : 'white',
    _hover: { bg: 'transparent' },
    _focus: { bg: 'transparent' },
    _active: { bg: 'transparent' },
    mr: 2,
    animate: { rotate: isIconActive ? 360 : 0 },
    transition: { duration: 0.25, ease: 'easeInOut' }
})

export const menuListProps: MenuListProps = {
    bg: 'gray.800',
    borderColor: 'gray.600',
    maxH: '250px',
    overflowY: 'auto',
    css: {
        '&::-webkit-scrollbar': {
            width: '8px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#555',
            borderRadius: '8px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#333'
        }
    }
}

export const textSaluteProps: TextProps = {
    color: 'white',
    fontSize: 'lg',
    display: { base: 'none', md: 'block' },
    mr: '1rem'
}

export const textUsernameProps: TextProps = {
    as: 'span',
    color: 'red.400',
    fontWeight: 'bold'
}

export const tooltipTextProps: TextProps = {
    display: { base: 'none', md: 'block' },
    color: 'white',
    fontSize: 'lg'
}
