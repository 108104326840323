import ReactDOM from 'react-dom/client'
import { ChakraProvider } from '@chakra-ui/react'
import App from './App'
import theme from './chakraTheme/theme'
import 'intersection-observer'
import './assets/fonts/fonts.css'

const container = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(container)

root.render(
    <ChakraProvider theme={theme}>
        <App />
    </ChakraProvider>
)
