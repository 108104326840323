import { Box, Button, Icon } from '@chakra-ui/react'
import SeriesVideoPlayer from '../SeriesVideoPlayer'
import { TiArrowBack } from 'react-icons/ti'
import { strings } from '../../../constants/strings'

interface VideoSectionProps {
    videoUrl: string
    subtitleUrl?: string | null
    resetVideo: () => void
}

const VideoSection: React.FC<VideoSectionProps> = ({ videoUrl, subtitleUrl, resetVideo }) => (
    <Box
        display={{ base: 'block', lg: 'none' }}
        width="100%"
        maxW={{ base: '90vw', lg: '800px' }}
        mb={4}
    >
        <SeriesVideoPlayer videoUrl={videoUrl} subtitleText={subtitleUrl || undefined} />
        <Button
            onClick={resetVideo}
            colorScheme="gray"
            mt={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
            width="full"
        >
            <Icon as={TiArrowBack} aria-label="Arrow back" fontSize="20px" />
            {strings.goBackToEpisodes}
        </Button>
    </Box>
)

export default VideoSection
