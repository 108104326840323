export const YEARS = Array.from({ length: 2024 - 1964 + 1 }, (_, i) =>
    (1964 + i).toString()
).reverse()

export const strings = {
    adminUser: 'fedmilo',
    appName: 'Mamovies',
    cast: 'Reparto',
    continue: 'Continuar',
    director: 'Director',
    genre: 'Género',
    goBackToEpisodes: 'Volver a episodios',
    logout: 'Cerrar sesión',
    logoutForInactivity: 'Por inactividad, tu sesión se cerrará en ',
    movies: 'Películas',
    moviesOf: 'Películas de',
    otherRatings: 'Otras calificaciones',
    plot: 'Trama',
    rateThisMovie: 'Califica esta película',
    salute: 'Hola,',
    seconds: 'segundos',
    seeAll: 'Últimas subidas',
    selectSeason: 'Seleccionar temporada',
    series: 'Series',
    updateMovies: 'Actualizar y ver si hay novedades',
    uploadVideo: 'Subir y convertir video',
    wantToContinueInSession: '¿Deseas continuar en la sesión?',
    year: 'Año',
    youStillThere: '¿Sigues ahí?'
}
