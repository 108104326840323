import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import axios from 'axios'
import {
    fetchSeriesDataApi,
    fetchSeasonEpisodesBasicApi,
    fetchEpisodeDataApi,
    fetchSeriesLatestTimestamp
} from '../api/seriesService'
import { SeriesInfo } from '../interfaces/series'
import { localStorageAdapter } from '../utils/localStorageAdapter'
import { API_SERIES_URL } from '../api/urls'

export interface Episode {
    filename: string
    type: string
    Title?: string
    Poster?: string
    Plot?: string
    season?: string
    episode?: string
    subtitleText?: string | null
}

interface SeriesState {
    loading: boolean
    isUpdatingSeries: boolean
    lastUpdated: number
    seriesInfoList: SeriesInfo[]
    selectedSeries: SeriesInfo | null
    isModalOpen: boolean
    episodesData: { [season: string]: Episode[] }
    seasonsData: any | null
    selectedSeason: string
    videoUrl: string | null
    subtitleUrl: string | null
    isLoadingEpisodes: boolean
    selectedEpisode: string | null
    selectedEpisodeTitle: string | null
    selectedSeasonEpisode: string | null

    fetchSeriesInfo: () => Promise<void>
    fetchSeasonEpisodes: (title: string, season: string) => Promise<void>
    setSelectedEpisode: (episode: string | null) => void
    setSelectedEpisodeTitle: (title: string | null) => void
    setSelectedSeasonEpisode: (episode: string | null) => void
    openModal: (series: SeriesInfo) => void
    closeModal: () => void
    setSelectedSeason: (season: string) => void
    setVideoUrl: (url: string | null) => void
    setSubtitleUrl: (url: string | null) => void
    setSeries: (seriesData: SeriesInfo[], latestTimestamp: number) => void
    checkAndUpdateSeries: () => Promise<void>
}

const useSeriesStore = create<SeriesState>()(
    devtools(
        persist(
            (set, get) => ({
                loading: true,
                isUpdatingSeries: false,
                lastUpdated: 0,
                seriesInfoList: [],
                selectedSeries: null,
                isModalOpen: false,
                episodesData: {},
                seasonsData: null,
                selectedSeason: '',
                videoUrl: null,
                subtitleUrl: null,
                isLoadingEpisodes: false,
                selectedEpisode: null,
                selectedEpisodeTitle: null,
                selectedSeasonEpisode: null,

                setSeries: (seriesData, latestTimestamp) =>
                    set({
                        seriesInfoList: seriesData,
                        lastUpdated: latestTimestamp,
                        loading: false
                    }),

                fetchSeriesInfo: async () => {
                    const { seriesInfoList, lastUpdated } = get()

                    set({ loading: true })

                    try {
                        const serverTimestamp = await fetchSeriesLatestTimestamp()
                        const needsUpdate = serverTimestamp > lastUpdated

                        if (!needsUpdate && seriesInfoList.length > 0) {
                            set({ loading: false })
                            return
                        }

                        const response = await axios.get(API_SERIES_URL)
                        const seasonsData = response.data

                        const seriesInfoPromises = Object.keys(seasonsData).map(
                            async seriesTitle => {
                                const seasons = seasonsData[seriesTitle].seasons
                                const seriesData = await fetchSeriesDataApi(seriesTitle)

                                return {
                                    title: seriesTitle,
                                    year: seriesData?.Year || 'Unknown',
                                    poster: seriesData?.Poster || 'placeholder_image_url',
                                    plot: seriesData?.Plot || 'No description available.',
                                    imdbRating: seriesData?.imdbRating || 'N/A',
                                    seasons
                                }
                            }
                        )

                        const seriesInfoResults = (await Promise.all(seriesInfoPromises)).filter(
                            (seriesInfo): seriesInfo is SeriesInfo => seriesInfo !== undefined
                        )

                        set({
                            seriesInfoList: seriesInfoResults,
                            seasonsData,
                            lastUpdated: serverTimestamp,
                            loading: false
                        })
                    } catch (error) {
                        console.error('Error fetching series:', error)
                    } finally {
                        set({ loading: false })
                    }
                },

                checkAndUpdateSeries: async () => {
                    console.log('Check for series updates...')
                    set({ loading: true })
                    const { lastUpdated, fetchSeriesInfo } = get()
                    const serverTimestamp = await fetchSeriesLatestTimestamp()

                    if (serverTimestamp > lastUpdated) {
                        console.log('Updating series...')
                        set({ isUpdatingSeries: true })
                        await fetchSeriesInfo()
                        set({ isUpdatingSeries: false })
                    }
                    set({ loading: false })
                },

                fetchSeasonEpisodes: async (title: string, season: string) => {
                    const { episodesData, seasonsData } = get()

                    if (episodesData[season] && episodesData[season].length > 0) {
                        return
                    }

                    set({ isLoadingEpisodes: true })

                    try {
                        if (!seasonsData || !seasonsData[title]) {
                            throw new Error(`No seasons data available for series ${title}`)
                        }

                        const backblazeEpisodes = seasonsData[title].seasons[season]

                        const basicEpisodes = await fetchSeasonEpisodesBasicApi(title, season)

                        const detailedEpisodesPromises = basicEpisodes.map(async (episode: any) => {
                            const episodeData = await fetchEpisodeDataApi(
                                title,
                                season,
                                episode.Episode
                            )

                            const backblazeVideo = backblazeEpisodes.find(
                                (ep: any) =>
                                    ep.filename
                                        .toLowerCase()
                                        .includes(
                                            `s${season
                                                .split(' ')[1]
                                                .padStart(2, '0')}e${episode.Episode.padStart(
                                                2,
                                                '0'
                                            )}`.toLowerCase()
                                        ) && ep.type === 'video'
                            )

                            const backblazeSubtitle = backblazeEpisodes.find(
                                (ep: any) =>
                                    ep.filename
                                        .toLowerCase()
                                        .includes(
                                            `s${season
                                                .split(' ')[1]
                                                .padStart(2, '0')}e${episode.Episode.padStart(
                                                2,
                                                '0'
                                            )}`.toLowerCase()
                                        ) && ep.type === 'subtitle'
                            )

                            let subtitleText = null
                            if (backblazeSubtitle) {
                                const subtitleUrl = `${API_SERIES_URL}/subtitles/${title}/${season}/${backblazeSubtitle.filename}`
                                const response = await axios.get(subtitleUrl)
                                subtitleText = response.data.subtitleText
                            }

                            return {
                                filename: backblazeVideo?.filename || '',
                                season: `S${season.split(' ')[1].padStart(2, '0')}`,
                                episode: `E${episode.Episode.padStart(2, '0')}`,
                                type: backblazeVideo?.type || 'video',
                                subtitleText: subtitleText,
                                Title: episodeData?.Title || 'Unknown Title',
                                Poster: episodeData?.Poster || 'placeholder_image_url',
                                Plot: episodeData?.Plot || 'No plot available'
                            }
                        })

                        const detailedEpisodes = await Promise.all(detailedEpisodesPromises)

                        set(state => ({
                            episodesData: { ...state.episodesData, [season]: detailedEpisodes },
                            isLoadingEpisodes: false
                        }))
                    } catch (error) {
                        console.error(
                            `Error fetching episodes for ${title}, Season ${season}:`,
                            error
                        )
                        set({ isLoadingEpisodes: false })
                    }
                },

                openModal: (series: SeriesInfo) =>
                    set({
                        selectedSeries: series,
                        isModalOpen: true,
                        selectedSeason: '',
                        videoUrl: null,
                        subtitleUrl: null
                    }),
                closeModal: () =>
                    set({
                        selectedSeries: null,
                        isModalOpen: false,
                        selectedSeason: '',
                        videoUrl: null,
                        subtitleUrl: null,
                        episodesData: {}
                    }),
                setSelectedSeason: (season: string) => set({ selectedSeason: season }),
                setVideoUrl: (url: string | null) => set({ videoUrl: url }),
                setSubtitleUrl: (url: string | null) => set({ subtitleUrl: url }),
                setSelectedEpisode: (episode: string | null) => set({ selectedEpisode: episode }),
                setSelectedEpisodeTitle: (title: string | null) =>
                    set({ selectedEpisodeTitle: title }),
                setSelectedSeasonEpisode: (episode: string | null) =>
                    set({ selectedSeasonEpisode: episode })
            }),
            { name: 'series-storage', storage: localStorageAdapter }
        )
    )
)

export default useSeriesStore
