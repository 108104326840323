import React, { useState } from 'react'
import {
    Box,
    Flex,
    Heading,
    Menu,
    MenuButton,
    MenuList,
    IconButton,
    Text,
    Tooltip,
    Button
} from '@chakra-ui/react'
import { FaSignOutAlt } from 'react-icons/fa'
import MovieGenreSelector from '../Movie/MovieGenreSelector'
import { strings } from '../../constants/strings'
import {
    boxProps,
    flexHamburguerProps,
    flexLogoProps,
    flexMenuAndSearchProps,
    flexMenuRightOptionsProps,
    flexProps,
    headingProps,
    menuButtonProps,
    menuListProps,
    movieButtonProps,
    seriesButtonProps,
    shuffleButtonProps,
    textSaluteProps,
    textUsernameProps
} from '../../constants/styles/headerStyles'
import useHeader from '../../hooks/useHeader'
import HamburguerMenu from './HamburguerMenu'
import SearchBar from './SearchBar'
import MotionIconButton from './MotionIconButton'

const Header: React.FC<{ onShuffle: () => void }> = ({ onShuffle }) => {
    const {
        toast,
        uniqueGenres,
        setSelectedGenre,
        setSelectedYear,
        username,
        handleLogout,
        inputValue,
        handleInputChange,
        clearInput,
        navigate,
        location,
        handleNavigation
    } = useHeader()

    const onViewChange = (view: 'movies' | 'series') => {
        const path = view === 'movies' ? '/movies' : '/series'
        navigate(path)
    }

    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const [isIconActive, setIsIconActive] = useState(false)

    const handleShuffleClick = () => {
        setIsIconActive(!isIconActive)
        setIsTooltipOpen(false)
        onShuffle()
    }

    return (
        <Box {...boxProps}>
            <Flex {...flexProps} align="center" wrap="wrap" position="relative">
                {/* Logo */}
                <Flex {...flexLogoProps}>
                    <Heading
                        {...headingProps}
                        onClick={() => onViewChange('movies')}
                        marginLeft={{
                            base: location.pathname === '/movies' ? '33%' : '17%',
                            md: '0'
                        }}
                    >
                        {strings.appName}
                    </Heading>
                </Flex>

                {/* Hamburguer Menu */}
                <Flex {...flexHamburguerProps}>
                    <HamburguerMenu
                        handleNavigation={handleNavigation}
                        handleLogout={handleLogout}
                        toast={toast}
                    />
                </Flex>

                {/* Searchbar and aditional options */}
                <Flex {...flexMenuAndSearchProps}>
                    <Button {...seriesButtonProps} onClick={() => onViewChange('series')}>
                        {strings.series}
                    </Button>

                    <>
                        {location.pathname === '/movies' ? (
                            <Menu isLazy>
                                <MenuButton as={Button} {...menuButtonProps}>
                                    {strings.movies}
                                </MenuButton>
                                <MenuList {...menuListProps}>
                                    <MovieGenreSelector
                                        genres={uniqueGenres}
                                        setSelectedGenre={setSelectedGenre}
                                        setSelectedYear={setSelectedYear}
                                    />
                                </MenuList>
                            </Menu>
                        ) : (
                            <Button {...movieButtonProps} onClick={() => onViewChange('movies')}>
                                {strings.movies}
                            </Button>
                        )}

                        <SearchBar
                            inputGroupProps={{
                                width: { base: '', md: '30vw' },
                                mr: '3rem',
                                ml: '3rem'
                            }}
                            inputValue={location.pathname !== '/movies' ? '' : inputValue}
                            clearInput={clearInput}
                            handleInputChange={handleInputChange}
                            isDisabled={location.pathname !== '/movies'}
                        />
                    </>
                </Flex>

                <Flex {...flexMenuRightOptionsProps}>
                    <Text {...textSaluteProps}>
                        {strings.salute} <Text {...textUsernameProps}>{username}</Text>
                    </Text>
                    {/* Shuffle Button */}

                    <Tooltip
                        label="Barajar Películas"
                        isOpen={isTooltipOpen}
                        onClose={() => setIsTooltipOpen(false)}
                    >
                        <MotionIconButton
                            {...shuffleButtonProps(isIconActive)}
                            onClick={handleShuffleClick}
                            disabled={location.pathname === '/series'}
                            onMouseEnter={() => setIsTooltipOpen(true)}
                            onMouseLeave={() => setIsTooltipOpen(false)}
                        />
                    </Tooltip>

                    <Tooltip label={strings.logout}>
                        <IconButton
                            fontSize="xl"
                            aria-label={strings.logout}
                            icon={<FaSignOutAlt />}
                            color="white"
                            bg="transparent"
                            _hover={{ color: 'red.400' }}
                            display={{ base: 'none', md: 'block' }}
                            onClick={() => handleLogout(toast)}
                        />
                    </Tooltip>
                </Flex>

                {/* Barra de búsqueda en una segunda fila solo en mobile */}
                {location.pathname === '/movies' && (
                    <SearchBar
                        inputGroupProps={{
                            width: '100%',
                            mt: 4,
                            display: { base: 'flex', md: 'none' }
                        }}
                        inputValue={inputValue}
                        clearInput={clearInput}
                        handleInputChange={handleInputChange}
                    />
                )}
            </Flex>
        </Box>
    )
}

export default Header
