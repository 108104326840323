import { HStack, Box, Progress, Text } from '@chakra-ui/react'

interface ProgressBarProps {
    value: number
    color: string
}

const ProgressBar = ({ value, color }: ProgressBarProps) => {
    return (
        <Box maxW="sm" w="100%">
            <HStack spacing="5">
                <Progress
                    value={value}
                    flex="1"
                    colorScheme={color}
                    borderRadius="md"
                    size="md"
                    width="100%"
                    bg="gray.700"
                    transition="width 0.3s ease"
                />
                <Text minW="40px" fontWeight="bold">
                    {value}%
                </Text>
            </HStack>
        </Box>
    )
}

export default ProgressBar
