import { useEffect } from 'react'
import { useToast } from '@chakra-ui/react'
import useAuthStore from '../stores/authStore'

const useSession = () => {
    const toast = useToast()

    const isAuthenticated = useAuthStore(state => state.isAuthenticated)
    const handleLoginSuccess = useAuthStore(state => state.handleLoginSuccess)
    const handleLogout = useAuthStore(state => state.handleLogout)
    const isLoading = useAuthStore(state => state.isLoading)
    const setLoading = useAuthStore(state => state.setLoading)
    const username = useAuthStore(state => state.username)

    useEffect(() => {
        const token = localStorage.getItem('authToken')
        const username = localStorage.getItem('username')
        if (token && username) {
            handleLoginSuccess(token, username)
        }
        setLoading(false)
    }, [handleLoginSuccess, setLoading])

    return {
        isAuthenticated,
        isLoading,
        username,
        handleLoginSuccess,
        handleLogout: () => handleLogout(toast)
    }
}

export default useSession
