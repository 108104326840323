import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'

interface ProtectedRouteProps {
    isAuthenticated: boolean
    username: string
    children: ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isAuthenticated, username, children }) => {
    if (!isAuthenticated || username !== 'fedmilo') {
        return <Navigate to="/" replace />
    }

    return <>{children}</>
}

export default ProtectedRoute
