import { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { toBase64 } from '../utils/toBase64'
import { fetchMovieProgress, saveMovieProgress } from '../api/movieService'
import { fetchSeriesProgress, saveSeriesProgress } from '../api/seriesService'
import { API_SERIES_URL } from '../api/urls'
import useSeriesStore from '../stores/seriesStore'

interface UseVideoPlayerProps {
    subtitleText?: string | null
    typeVideo: 'movie' | 'series'
}

const useVideoPlayer = ({
    subtitleText,
    typeVideo
}: UseVideoPlayerProps): {
    areSubtitlesVisible: boolean
    clearHoverTime: () => void
    containerRef: React.RefObject<HTMLDivElement>
    currentVideoUrl: string | null
    duration: number
    goToNextEpisode: () => void
    goToPreviousEpisode: () => void
    handleDuration: (videoDuration: number) => void
    handleFullScreen: () => void
    handleMouseMove: () => void
    handleProgress: (state: { played: number }) => void
    handleSeekChange: (value: number) => void
    handleSliderHover: (event: React.MouseEvent<HTMLDivElement>, sliderWidth: number) => void
    handleVolumeChange: (value: number) => void
    hoverTime: number | null
    isLoading: boolean
    isMuted: boolean
    isPlaying: boolean
    progress: number
    remainingTime: number
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
    showControls: boolean
    skipBackward: (seconds: number) => void
    skipForward: (seconds: number) => void
    subtitleUrl: string | null
    toggleMute: () => void
    togglePlayPause: (playState?: boolean) => void
    toggleSubtitles: () => void
    videoRef: React.RefObject<ReactPlayer>
    volume: number
    hasPreviousEpisode: boolean
    hasNextEpisode: boolean
    currentSubtitleText: string | null
} => {
    const setSelectedEpisodeTitle = useSeriesStore(state => state.setSelectedEpisodeTitle)
    const setSelectedEpisode = useSeriesStore(state => state.setSelectedEpisode)
    const setSelectedSeasonEpisode = useSeriesStore(state => state.setSelectedSeasonEpisode)

    const containerRef = useRef<HTMLDivElement>(null)
    const hideControlsTimeout = useRef<NodeJS.Timeout | null>(null)
    const videoRef = useRef<ReactPlayer>(null)
    const savedProgressRef = useRef<number>(0)
    const subtitleUrl = subtitleText ? `data:text/vtt;base64,${toBase64(subtitleText)}` : null

    const [currentVideoUrl, setCurrentVideoUrl] = useState<string | null>(null)
    const [areSubtitlesVisible, setAreSubtitlesVisible] = useState(true)
    const [duration, setDuration] = useState(0)
    const [hoverTime, setHoverTime] = useState<number | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isMuted, setIsMuted] = useState(false)
    const [isPlaying, setIsPlaying] = useState(true)
    const [progress, setProgress] = useState(0)
    const [showControls, setShowControls] = useState(true)
    const [volume, setVolume] = useState(0.5)

    const [currentEpisode, setCurrentEpisode] = useState<number>(() => {
        const episodeString = localStorage.getItem('selectedEpisode')
        const match = episodeString?.match(/\d+/)
        return match ? parseInt(match[0], 10) : 1
    })
    const [currentSeason, setCurrentSeason] = useState<number>(() => {
        const seasonString = localStorage.getItem('selectedSeason')
        const match = seasonString?.match(/\d+/)
        return match ? parseInt(match[0], 10) : 1
    })
    const [currentSubtitleText, setCurrentSubtitleText] = useState<string | null>(
        subtitleText ? `data:text/vtt;base64,${toBase64(subtitleText)}` : null
    )
    const [hasPreviousEpisode, setHasPreviousEpisode] = useState(false)
    const [hasNextEpisode, setHasNextEpisode] = useState(true)

    const seriesStorage = localStorage.getItem('series-storage')
    const movieStorage = localStorage.getItem('movie-storage')
    const username = localStorage.getItem('username')
    const title =
        typeVideo === 'movie'
            ? movieStorage
                ? JSON.parse(movieStorage).state.selectedMovie.title
                : ''
            : seriesStorage
            ? JSON.parse(seriesStorage).state.selectedSeries.title
            : ''
    const year =
        typeVideo === 'movie'
            ? movieStorage
                ? JSON.parse(movieStorage).state.selectedMovie.year
                : ''
            : undefined

    const currentTime = progress * duration
    const remainingTime = duration - currentTime

    useEffect(() => {
        if (typeVideo === 'series' && seriesStorage) {
            if (videoRef.current) {
                const videoElement = videoRef.current.getInternalPlayer() as HTMLVideoElement
                if (videoElement?.textTracks) {
                    Array.from(videoElement.textTracks).forEach(track => {
                        track.mode = 'hidden'
                        while (track.cues?.length) {
                            track.removeCue(track.cues[0])
                        }
                    })
                }
            }

            const storedSeries = seriesStorage ? JSON.parse(seriesStorage) : null
            const episodesData = storedSeries.state.episodesData

            const seasonEpisodes = episodesData?.[`Season ${currentSeason}`] || []
            const episodeData = seasonEpisodes[currentEpisode - 1]

            setHasPreviousEpisode(currentEpisode > 1 || currentSeason > 1)
            setHasNextEpisode(
                episodesData &&
                    (currentEpisode < seasonEpisodes.length ||
                        currentSeason < Object.keys(episodesData).length)
            )

            if (episodeData) {
                const seriesUrl = `${API_SERIES_URL}/stream/${title}/Season ${currentSeason}/${episodeData.filename}`
                setIsPlaying(true)
                setCurrentVideoUrl(seriesUrl)
                setSelectedEpisodeTitle(episodeData.Title)
                setSelectedEpisode(episodeData.episode)
                setSelectedSeasonEpisode(episodeData.season)
                setCurrentSubtitleText(`data:text/vtt;base64,${toBase64(episodeData.subtitleText)}`)
            }
        }
    }, [currentEpisode, currentSeason, typeVideo, seriesStorage])

    const goToNextEpisode = () => {
        setIsLoading(true)
        if (typeVideo === 'series' && seriesStorage) {
            const storedSeries = seriesStorage ? JSON.parse(seriesStorage) : null
            const episodesData = storedSeries.state.episodesData
            const seasonEpisodes = episodesData?.[`Season ${currentSeason}`] || []

            setCurrentSubtitleText(null)

            if (currentEpisode < seasonEpisodes.length) {
                setCurrentEpisode(currentEpisode + 1)
                localStorage.setItem('selectedEpisode', String(currentEpisode + 1))
            } else if (episodesData && currentSeason < Object.keys(episodesData).length) {
                setCurrentSeason(currentSeason + 1)
                setCurrentEpisode(1)
                localStorage.setItem('selectedSeason', String(currentSeason + 1))
                localStorage.setItem('selectedEpisode', '1')
            }
        }
    }

    const goToPreviousEpisode = () => {
        setIsLoading(true)
        setCurrentSubtitleText(null)
        if (currentEpisode > 1) {
            setCurrentEpisode(currentEpisode - 1)
            localStorage.setItem('selectedEpisode', String(currentEpisode - 1))
        } else if (currentSeason > 1) {
            const storedSeries = seriesStorage ? JSON.parse(seriesStorage) : null
            const episodesData = storedSeries.state.episodesData
            const previousSeasonEpisodes = episodesData?.[`Season ${currentSeason - 1}`] || []

            setCurrentSeason(currentSeason - 1)
            setCurrentEpisode(previousSeasonEpisodes.length)
            localStorage.setItem('selectedSeason', String(currentSeason - 1))
            localStorage.setItem('selectedEpisode', String(previousSeasonEpisodes.length))
        }
    }

    // Load progress on mount
    useEffect(() => {
        const loadProgress = async () => {
            if (typeVideo === 'movie') {
                const savedProgress = await fetchMovieProgress(username, title, year || '')
                setProgress(savedProgress.progress)
                setIsMuted(savedProgress.isMuted)
                savedProgressRef.current = savedProgress.progress
                if (videoRef.current) {
                    videoRef.current.seekTo(savedProgress.progress)
                }
            } else if (typeVideo === 'series') {
                const savedProgress = await fetchSeriesProgress(
                    username,
                    title,
                    currentSeason,
                    currentEpisode
                )
                setProgress(savedProgress.progress)
                setIsMuted(savedProgress.isMuted)
                savedProgressRef.current = savedProgress.progress
                if (videoRef.current) {
                    videoRef.current.seekTo(savedProgress.progress)
                }
            }
        }
        loadProgress()
    }, [typeVideo, username, title, year, currentSeason, currentEpisode])

    // Save progress on unmount
    useEffect(() => {
        return () => {
            if (typeVideo === 'movie') {
                saveMovieProgress(username, title, year || '', savedProgressRef.current, isMuted)
            } else if (typeVideo === 'series') {
                saveSeriesProgress(
                    username,
                    title,
                    currentSeason,
                    currentEpisode,
                    savedProgressRef.current,
                    isMuted
                )
            }
        }
    }, [typeVideo, username, title, year, currentSeason, currentEpisode, isMuted])

    useEffect(() => {
        if (progress >= 1) {
            goToNextEpisode()
            setIsPlaying(true)
        }
    }, [progress])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            switch (event.code) {
                case 'Space':
                    event.preventDefault()
                    togglePlayPause()
                    break
                case 'ArrowRight':
                    event.preventDefault()
                    skipForward(10)
                    break
                case 'ArrowLeft':
                    event.preventDefault()
                    skipBackward(10)
                    break
                case 'KeyM':
                    event.preventDefault()
                    toggleMute()
                    break
                case 'KeyF':
                    event.preventDefault()
                    handleFullScreen()
                    break
                default:
                    break
            }
        }

        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [isPlaying, videoRef, isMuted])

    useEffect(() => {
        if (videoRef.current) {
            const videoElement = videoRef.current.getInternalPlayer() as HTMLVideoElement
            const tracks = videoElement?.textTracks

            if (tracks) {
                for (let i = 0; i < tracks.length; i++) {
                    const track = tracks[i]
                    track.mode = areSubtitlesVisible ? 'showing' : 'hidden'
                }
            }
        }
    }, [areSubtitlesVisible, currentSubtitleText])

    const resetHideControlsTimeout = () => {
        if (hideControlsTimeout.current) {
            clearTimeout(hideControlsTimeout.current)
        }
        hideControlsTimeout.current = setTimeout(() => {
            setShowControls(false)
        }, 3000)
    }

    const handleMouseMove = () => {
        setShowControls(true)
        resetHideControlsTimeout()
    }

    const togglePlayPause = (playState?: boolean) => {
        setIsPlaying(playState !== undefined ? playState : !isPlaying)
    }

    const handleVolumeChange = (value: number) => {
        setVolume(value)
        setIsMuted(value === 0)
    }

    const toggleMute = () => {
        setIsMuted(!isMuted)
    }

    const handleFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen()
        } else if (containerRef.current) {
            containerRef.current.requestFullscreen()
        }
    }

    const skipForward = (seconds: number) => {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime + seconds)
        }
    }

    const skipBackward = (seconds: number) => {
        if (videoRef.current) {
            const currentTime = videoRef.current.getCurrentTime()
            videoRef.current.seekTo(currentTime - seconds)
        }
    }

    const handleProgress = (state: { played: number }) => {
        setProgress(state.played)
        savedProgressRef.current = state.played
    }

    const handleDuration = (videoDuration: number) => {
        setDuration(videoDuration)
    }

    const handleSliderHover = (event: React.MouseEvent<HTMLDivElement>, sliderWidth: number) => {
        const hoverPosition = event.clientX - event.currentTarget.getBoundingClientRect().left
        const hoverPercentage = hoverPosition / sliderWidth
        setHoverTime(hoverPercentage * duration)
    }

    const toggleSubtitles = () => {
        setAreSubtitlesVisible(!areSubtitlesVisible)
    }

    const clearHoverTime = () => {
        setHoverTime(null)
    }

    return {
        areSubtitlesVisible,
        clearHoverTime,
        containerRef,
        currentVideoUrl,
        duration,
        goToNextEpisode,
        goToPreviousEpisode,
        handleDuration,
        handleFullScreen,
        handleMouseMove,
        handleProgress,
        handleSeekChange: (value: number) => {
            if (videoRef.current) {
                videoRef.current.seekTo(value)
                setProgress(value)
                savedProgressRef.current = value
            }
        },
        handleSliderHover,
        handleVolumeChange,
        hoverTime,
        isLoading,
        isMuted,
        isPlaying,
        progress,
        remainingTime,
        setIsLoading,
        setIsPlaying,
        showControls,
        skipBackward,
        skipForward,
        subtitleUrl,
        toggleMute,
        togglePlayPause,
        toggleSubtitles,
        videoRef,
        volume,
        hasPreviousEpisode,
        hasNextEpisode,
        currentSubtitleText
    }
}

export default useVideoPlayer
