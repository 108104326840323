export const genreTranslations: { [key: string]: string } = {
    Drama: 'Drama',
    Horror: 'Terror',
    'Sci-Fi': 'Ciencia Ficción',
    Thriller: 'Suspenso',
    Mystery: 'Misterio',
    Action: 'Acción',
    Adventure: 'Aventura',
    Fantasy: 'Fantasía',
    Crime: 'Crimen',
    Comedy: 'Comedia',
    Sport: 'Deporte',
    Documentary: 'Documental',
    Biography: 'Biografía',
    History: 'Historia',
    'Reality-TV': 'Reality-TV',
    Music: 'Música',
    Western: 'Western',
    Short: 'Corto',
    Romance: 'Romance',
    Animation: 'Animación'
}
