import React from 'react'
import ReactPlayer from 'react-player'

interface VideoPlayerProps {
    videoRef: React.RefObject<ReactPlayer>
    videoUrl: string
    isPlaying: boolean
    isMuted: boolean
    volume: number
    togglePlayPause: (playing: boolean) => void
    handleProgress: (state: { played: number; playedSeconds: number }) => void
    handleDuration: (duration: number) => void
    subtitleUrl: string | null
    setIsLoading: (loading: boolean) => void
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({
    videoRef,
    videoUrl,
    isPlaying,
    isMuted,
    volume,
    togglePlayPause,
    handleProgress,
    handleDuration,
    subtitleUrl,
    setIsLoading
}) => {
    return (
        <ReactPlayer
            ref={videoRef}
            url={videoUrl}
            playing={isPlaying}
            muted={isMuted}
            volume={volume}
            controls={false}
            width="100%"
            height="100%"
            onPlay={() => togglePlayPause(true)}
            onPause={() => togglePlayPause(false)}
            onReady={() => setIsLoading(false)}
            onProgress={handleProgress}
            onDuration={handleDuration}
            playsinline
            onError={e => console.error('ReactPlayer error:', e)}
            config={{
                file: {
                    attributes: {
                        crossOrigin: 'anonymous',
                        playsInline: true,
                        muted: isMuted
                    },
                    tracks: subtitleUrl
                        ? [
                              {
                                  kind: 'subtitles',
                                  src: subtitleUrl,
                                  srcLang: 'es',
                                  label: 'Spanish',
                                  default: true
                              }
                          ]
                        : []
                }
            }}
            style={{
                borderRadius: '8px'
            }}
        />
    )
}

export default VideoPlayer
