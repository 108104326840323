import { Box } from '@chakra-ui/react'
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Header from './components/Common/Header'
import Login from './components/User/Login'
import VideoUpload from './components/Common/VideoUpload'
import ProtectedRoute from './components/Common/ProtectedRoute'
import ViewContent from './components/Common/ViewContent'
import useSession from './hooks/useSession'
import useMovieList from './hooks/useMovieList'

import { squircle } from 'ldrs'
import SpinnerCentered from './components/Common/SpinnerCentered'

squircle.register()

interface AppContentProps {
    isAuthenticated: boolean
    username: string
    shuffleTrigger: boolean
    movieListData: any
}

const AppContent = ({
    isAuthenticated,
    username,
    shuffleTrigger,
    movieListData
}: AppContentProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const location = useLocation()

    useEffect(() => {
        setIsLoading(true)
        const timeoutId = setTimeout(() => setIsLoading(false), 500)
        return () => clearTimeout(timeoutId)
    }, [location])

    if (isLoading) {
        return <SpinnerCentered />
    }

    return (
        <Box>
            <Routes>
                <Route path="/" element={<Navigate to="/movies" replace />} />
                <Route
                    path="/movies"
                    element={
                        <ViewContent
                            selectedView="movies"
                            shuffleTrigger={shuffleTrigger}
                            movieListData={movieListData}
                        />
                    }
                />
                <Route path="/series" element={<ViewContent selectedView="series" />} />
                <Route
                    path="/upload"
                    element={
                        <ProtectedRoute isAuthenticated={isAuthenticated} username={username}>
                            <VideoUpload />
                        </ProtectedRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/movies" replace />} />
            </Routes>
        </Box>
    )
}

const App = () => {
    const { isAuthenticated, username } = useSession()
    const [shuffleTrigger, setShuffleTrigger] = useState(false)

    const movieListData = useMovieList()

    const handleShuffle = () => {
        setShuffleTrigger(prev => !prev)
    }

    return (
        <Router>
            <Box width="100%">
                {isAuthenticated ? (
                    <>
                        <Header onShuffle={handleShuffle} />
                        <AppContent
                            isAuthenticated={isAuthenticated}
                            username={username}
                            shuffleTrigger={shuffleTrigger}
                            movieListData={movieListData}
                        />
                    </>
                ) : (
                    <Login />
                )}
            </Box>
        </Router>
    )
}

export default App
