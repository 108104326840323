import { Flex } from '@chakra-ui/react'
import PlayBackControl from './PlayBackControl'
import ProgressSliderControl from './ProgressSliderControl'
import { flexControlsProps } from '../../../constants/styles/playerStyles'
import VolumeControl from './VolumeControl'
import AditionalControls from './AditionalControls'

interface VideoPlayerControlsProps {
    areSubtitlesVisible: boolean
    clearHoverTime: () => void
    duration: number
    handleFullScreen: () => void
    handleSeekChange: (value: number) => void
    handleSliderHover: (event: React.MouseEvent<HTMLDivElement>, offsetWidth: number) => void
    handleVolumeChange: (value: number) => void
    hoverTime: number | null
    isMuted: boolean
    isPlaying: boolean
    progress: number
    remainingTime: number
    showControls: boolean
    skipBackward: (seconds: number) => void
    skipForward: (seconds: number) => void
    subtitleUrl: string | null
    toggleMute: () => void
    togglePlayPause: () => void
    toggleSubtitles: () => void
    volume: number
    goToNextEpisode?: () => void
    goToPreviousEpisode?: () => void
    hasPreviousEpisode?: boolean
    hasNextEpisode?: boolean
    videoType: 'movie' | 'series'
}

const VideoPlayerControls: React.FC<VideoPlayerControlsProps> = ({
    areSubtitlesVisible,
    clearHoverTime,
    duration,
    handleFullScreen,
    handleSeekChange,
    handleSliderHover,
    handleVolumeChange,
    hoverTime,
    isMuted,
    isPlaying,
    progress,
    remainingTime,
    showControls,
    skipBackward,
    skipForward,
    subtitleUrl,
    toggleMute,
    togglePlayPause,
    toggleSubtitles,
    volume,
    goToNextEpisode,
    goToPreviousEpisode,
    hasPreviousEpisode,
    hasNextEpisode,
    videoType
}) => (
    <>
        <PlayBackControl
            isPlaying={isPlaying}
            handlePlayPause={togglePlayPause}
            skipBackward={skipBackward}
            skipForward={skipForward}
        />

        <ProgressSliderControl
            progress={progress}
            handleSeekChange={handleSeekChange}
            handleSliderHover={handleSliderHover}
            clearHoverTime={clearHoverTime}
            hoverTime={hoverTime}
            duration={duration}
            remainingTime={remainingTime}
        />

        <Flex {...flexControlsProps(showControls)}>
            <VolumeControl
                handleVolumeChange={handleVolumeChange}
                isMuted={isMuted}
                toggleMute={toggleMute}
                volume={volume}
            />

            {/* Subtitles & Full Screen controls */}
            <AditionalControls
                videoType={videoType}
                areSubtitlesVisible={areSubtitlesVisible}
                handleFullScreen={handleFullScreen}
                subtitleUrl={subtitleUrl}
                toggleSubtitles={toggleSubtitles}
                goToNextEpisode={goToNextEpisode}
                goToPreviousEpisode={goToPreviousEpisode}
                hasPreviousEpisode={hasPreviousEpisode}
                hasNextEpisode={hasNextEpisode}
            />
        </Flex>
    </>
)

export default VideoPlayerControls
