import { jwtDecode } from 'jwt-decode'

export const getUsernameFromToken = (token: string): string | null => {
    try {
        const decodedToken: any = jwtDecode(token)
        return decodedToken?.username || null
    } catch (error) {
        console.error('Error decoding token:', error)
        return null
    }
}
