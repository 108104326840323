export const localStorageAdapter = {
    getItem: (name: string) => {
        const item = localStorage.getItem(name)
        return item ? JSON.parse(item) : null
    },
    setItem: (name: string, value: unknown) => {
        localStorage.setItem(name, JSON.stringify(value))
    },
    removeItem: (name: string) => {
        localStorage.removeItem(name)
    }
}
