import { useEffect, useState } from 'react'
import MovieList from '../Movie/MovieList'
import SeriesList from '../Series/SeriesList'

interface ViewContentProps {
    selectedView: 'movies' | 'series'
    shuffleTrigger?: boolean
    movieListData?: any
}

const ViewContent = ({ selectedView, shuffleTrigger, movieListData }: ViewContentProps) => {
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        const timer = setTimeout(() => setLoading(false), 500)
        return () => clearTimeout(timer)
    }, [selectedView])

    return (
        <>
            {loading ? (
                <></>
            ) : selectedView === 'movies' ? (
                <MovieList shuffleTrigger={shuffleTrigger || false} movieListData={movieListData} />
            ) : (
                <SeriesList />
            )}
        </>
    )
}

export default ViewContent
