import { useEffect } from 'react'
import { Box, Grid, GridItem, Image } from '@chakra-ui/react'
import useSeriesStore from '../../stores/seriesStore'
import SeriesDetailModal from './SeriesDetailModal'
import { SeriesInfo } from '../../interfaces/series'
import SpinnerCentered from '../Common/SpinnerCentered'

const SeriesList = () => {
    const {
        loading,
        isUpdatingSeries,
        seriesInfoList,
        lastUpdated,
        fetchSeriesInfo,
        setSeries,
        selectedSeries,
        openModal,
        closeModal,
        checkAndUpdateSeries
    } = useSeriesStore()

    useEffect(() => {
        checkAndUpdateSeries()
    }, [fetchSeriesInfo, lastUpdated, setSeries, checkAndUpdateSeries])

    return (
        <Box p={[1, 4]} bg="gray.900" minH="100vh" position="relative">
            {loading || isUpdatingSeries ? (
                <SpinnerCentered />
            ) : (
                <Grid
                    templateColumns={[
                        'repeat(2, 1fr)',
                        'repeat(3, 1fr)',
                        'repeat(4 , 1fr)',
                        'repeat(5 , 1fr)',
                        'repeat(6 , 1fr)'
                    ]}
                    gap={{ base: 4, md: 5 }}
                >
                    {seriesInfoList.map((seriesInfo: SeriesInfo) => (
                        <GridItem
                            key={seriesInfo.title}
                            onClick={() => {
                                closeModal()
                                openModal(seriesInfo)
                            }}
                            _hover={{
                                transform: 'scale(1.05)',
                                cursor: 'pointer'
                            }}
                            position="relative"
                            transition="transform 0.2s ease, box-shadow 0.2s ease"
                            textAlign="center"
                            borderRadius={['10px', '20px']}
                        >
                            <Image
                                src={seriesInfo.poster}
                                objectFit="cover"
                                alt={seriesInfo.title}
                                maxHeight={['200px', '260px', '320px']}
                                width={['180px', '200px', '240px']}
                                height={['220px', '260px', '320px']}
                                borderRadius={['10px', '20px']}
                            />
                        </GridItem>
                    ))}
                </Grid>
            )}
            {selectedSeries && <SeriesDetailModal />}
        </Box>
    )
}

export default SeriesList
