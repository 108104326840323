import axios from 'axios'
import { API_MOVIE_PROGRESS_URL, API_MOVIES_URL } from './urls'
import { cleanTitle } from '../utils/cleanMovieTitle'
import { fetchOmdb } from './externalService'
import { VideoProgressResponse } from '../interfaces/global'

/**
 * Fetches the latest timestamp from the local API
 * @returns {Promise<number>} The latest timestamp
 */
export const fetchLatestTimestamp = async (): Promise<number> => {
    try {
        const response = await axios.get(`${API_MOVIES_URL}/latest-timestamp`)
        return response.data.latestTimestamp
    } catch (error) {
        console.error('Error fetching latest timestamp:', error)
        return 0
    }
}

/**
 * Fetches movies from the local API
 * @param {string} username - The username
 * @param {number} page - The page number
 * @returns {Promise<any>} The movies data
 */
export const getStoredOrFetchedMovieData = async (
    title: string,
    year: string,
    type: 'details' | 'poster'
): Promise<{ data: any; key: string } | null> => {
    const cleanedTitle = cleanTitle(title)
    const key = type === 'poster' ? `${cleanedTitle}_${year}_poster` : `${cleanedTitle}_${year}`
    const storedData = localStorage.getItem(key)

    if (storedData) {
        return type === 'poster' ? { data: storedData, key } : { data: JSON.parse(storedData), key }
    }

    // Si no se encuentra en la API local, intentamos obtener desde OMDb
    const omdbData = await fetchOmdb(year, cleanedTitle)
    if (omdbData) {
        const dataToStore = type === 'poster' ? omdbData.Poster : omdbData
        localStorage.setItem(key, type === 'poster' ? dataToStore : JSON.stringify(dataToStore))
        return { data: dataToStore, key }
    }

    return null
}

/**
 * Fetches movie progress from the local API
 * @param {string} username - The username
 * @param {string} title - The title of the movie
 * @param {string} year - The year of the movie
 * @returns {Promise<VideoProgressResponse>} The movie progress
 */
export const fetchMovieProgress = async (
    username: string | null,
    title: string,
    year: string
): Promise<VideoProgressResponse> => {
    try {
        const response = await axios.get(`${API_MOVIE_PROGRESS_URL}`, {
            params: { username, title, year }
        })
        return { progress: response.data.progress, isMuted: response.data.isMuted }
    } catch (error) {
        console.error(`Error fetching movie progress for ${title} (${year}):`, error)
        return { progress: 0, isMuted: false }
    }
}

/**
 * Saves movie progress to the local API
 * @param {string} username - The username
 * @param {string} title - The title of the movie
 * @param {string} year - The year of the movie
 * @param {number} progress - The progress of the movie
 * @param {boolean} isMuted - Whether the movie is muted
 */
export const saveMovieProgress = async (
    username: string | null,
    title: string,
    year: string,
    progress: number,
    isMuted: boolean
) => {
    try {
        await axios.post(`${API_MOVIE_PROGRESS_URL}/save`, {
            username,
            title,
            year,
            progress,
            isMuted
        })
    } catch (error) {
        console.error(`Error saving movie progress for ${title} (${year}):`, error)
    }
}
