import { Flex } from '@chakra-ui/react'
import { squircle } from 'ldrs'

squircle.register()

const SpinnerCentered = () => (
    <Flex
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        align="center"
        justify="center"
        zIndex={1}
    >
        <l-squircle
            size="37"
            stroke="5"
            stroke-length="0.15"
            bg-opacity="0.1"
            speed="0.9"
            color="white"
        ></l-squircle>
    </Flex>
)

export default SpinnerCentered
