import { create, StoreApi, UseBoundStore } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { Movie } from '../interfaces/movie'
import { localStorageAdapter } from '../utils/localStorageAdapter'
import { GenericState } from '../interfaces/global'
import { fetchLatestTimestamp } from '../api/movieService'

interface MovieState {
    currentVideoUrl: string | null
    currentSubtitleText: string | null
    currentMovieDetails: any
    displayedMovies: Movie[]
    isLoading: boolean
    isLoadingPlayer: boolean
    isModalReady: boolean
    isUpdatingMovies: boolean
    lastUpdated: number
    movies: { [key: string]: Movie[] }
    moviePage: number
    moviePosters: { [key: string]: string }
    movieDetails: { [key: string]: any }
    selectedMovie: { title: string; year: string } | null

    fetchLatestTimestamp: () => Promise<number>
    setCurrentSubtitleText: (currentSubtitleText: string | null) => void
    setCurrentVideoUrl: (currentVideoUrl: string | null) => void
    setDisplayedMovies: (displayedMovies: Movie[]) => void
    setIsLoading: (isLoading: boolean) => void
    setIsModalReady: (isModalReady: boolean) => void
    setIsUpdatingMovies: (isUpdating: boolean) => void
    setMovies: (movies: { [key: string]: Movie[] }, timestamp?: number) => void
    setMoviePage: (moviePage: number) => void
    setMovieDetails: (movieDetails: { [key: string]: any }) => void
    setMoviePosters: (moviePosters: { [key: string]: string }) => void
    setSelectedMovie: (selectedMovie: { title: string; year: string } | null) => void
}

export const createStoreWithSelectors = <T extends GenericState>(
    store: UseBoundStore<StoreApi<T>>
): (<K extends keyof T>(keys: K[]) => Pick<T, K>) => {
    const useStore: <K extends keyof T>(keys: K[]) => Pick<T, K> = <K extends keyof T>(
        keys: K[]
    ) => {
        const selectedState = store(
            state =>
                keys.reduce((acc, cur) => {
                    acc[cur] = state[cur]
                    return acc
                }, {} as T) as Pick<T, K>
        )
        return selectedState
    }

    return useStore
}

const useMovieStore = create<MovieState>()(
    devtools(
        persist(
            (set, get) => ({
                currentVideoUrl: null,
                currentSubtitleText: null,
                currentMovieDetails: null,
                displayedMovies: [],
                isLoading: true,
                isLoadingPlayer: false,
                isModalReady: false,
                isUpdatingMovies: false,
                lastUpdated: 0,
                movies: {},
                moviePage: 1,
                moviePosters: {},
                movieDetails: {},
                selectedMovie: null,

                setCurrentSubtitleText: (currentSubtitleText: string | null) =>
                    set({ currentSubtitleText }),
                setDisplayedMovies: (displayedMovies: Movie[]) => set({ displayedMovies }),
                setCurrentVideoUrl: (currentVideoUrl: string | null) => set({ currentVideoUrl }),
                setIsLoading: (isLoading: boolean) => set({ isLoading }),
                setIsModalReady: (isModalReady: boolean) => set({ isModalReady }),
                setMovies: (movies: { [key: string]: Movie[] }, timestamp?: number) => {
                    const storedMovies = get().movies
                    const { lastUpdated } = get()

                    if (!timestamp) return set({ movies })

                    if (timestamp > lastUpdated) {
                        set({ movies: { ...storedMovies, ...movies }, lastUpdated: timestamp })
                    }
                },
                setMovieDetails: (movieDetails: { [key: string]: any }) => set({ movieDetails }),
                setMoviePage: (moviePage: number) => set({ moviePage }),
                setMoviePosters: (moviePosters: { [key: string]: string }) => set({ moviePosters }),
                setSelectedMovie: (selectedMovie: { title: string; year: string } | null) =>
                    set({ selectedMovie }),
                fetchLatestTimestamp,
                setIsUpdatingMovies: (isUpdating: boolean) => set({ isUpdatingMovies: isUpdating })
            }),
            {
                name: 'movie-storage',
                storage: localStorageAdapter
            }
        )
    )
)

export default useMovieStore
