import { extendTheme, ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
    initialColorMode: 'dark',
    useSystemColorMode: false
}

const theme = extendTheme({
    config,
    styles: {
        global: {
            body: {
                bg: 'gray.900',
                color: 'whiteAlpha.900',
                transition: 'background-color 0.2s ease-in-out',
                fontWeight: 'normal'
            },
            '::-webkit-scrollbar': {
                width: '0px'
            },
            '::-webkit-scrollbar-track': {
                background: '#2D3748'
            },
            '::-webkit-scrollbar-thumb': {
                background: '#4A5568',
                borderRadius: '4px',
                border: '2px solid #2D3748'
            },
            '::-webkit-scrollbar-thumb:hover': {
                background: '#718096'
            }
        }
    },
    components: {
        Select: {
            baseStyle: {
                field: {
                    bg: 'gray.800',
                    color: 'whiteAlpha.900',
                    _placeholder: {
                        color: 'gray.500'
                    }
                }
            },
            sizes: {
                md: {
                    field: {
                        borderRadius: 'md',
                        h: '2.5rem'
                    }
                }
            },
            variants: {
                outline: {
                    field: {
                        borderColor: 'gray.600',
                        _hover: {
                            borderColor: 'gray.400'
                        },
                        _focus: {
                            borderColor: 'blue.500',
                            boxShadow: '0 0 0 1px blue.500'
                        }
                    }
                }
            },
            defaultProps: {
                size: 'md',
                variant: 'outline'
            }
        }
    },
    colors: {
        brand: {
            900: '#1a202c',
            800: '#2d3748',
            700: '#4a5568'
        }
    },
    fonts: {
        heading: `'Obvia', sans-serif`,
        body: `'Obvia', sans-serif`
    }
})

export default theme
