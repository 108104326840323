import { MenuItem } from '@chakra-ui/react'
import { genreTranslations } from '../../utils/genreTranslations'
import { strings } from '../../constants/strings'

interface MovieGenreSelectorProps {
    genres: string[]
    setSelectedGenre: (genre: string | null) => void
    setSelectedYear: (year: null) => void
}

const MovieGenreSelector = ({
    genres,
    setSelectedGenre,
    setSelectedYear
}: MovieGenreSelectorProps) => {
    return (
        <>
            <MenuItem
                onClick={() => {
                    setSelectedGenre(null)
                    setSelectedYear(null)
                }}
                bg="gray.800"
                color="white"
                _hover={{ bg: 'gray.700', color: 'red.400' }}
            >
                {strings.seeAll}
            </MenuItem>
            {genres
                .filter(genre => genre !== 'N/A')
                .map((genre: string) => (
                    <MenuItem
                        key={genre}
                        onClick={() => {
                            setSelectedGenre(genre)
                            setSelectedYear(null)
                        }}
                        bg="gray.800"
                        color="white"
                        _hover={{ bg: 'gray.700', color: 'red.400' }}
                    >
                        {genreTranslations[genre] || genre}
                    </MenuItem>
                ))}
        </>
    )
}

export default MovieGenreSelector
