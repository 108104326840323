import { Flex, IconButton } from '@chakra-ui/react'
import { FaPause, FaPlay } from 'react-icons/fa'
import { MdForward10, MdReplay10 } from 'react-icons/md'

interface PlayBackControlProps {
    handlePlayPause: () => void
    isPlaying: boolean
    skipBackward: (seconds: number) => void
    skipForward: (seconds: number) => void
}

const PlayBackControl: React.FC<PlayBackControlProps> = ({
    handlePlayPause,
    isPlaying,
    skipBackward,
    skipForward
}) => (
    <Flex
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        zIndex={1}
        align="center"
        gap={5}
    >
        <IconButton
            aria-label="Retroceder 10 segundos"
            icon={<MdReplay10 />}
            onClick={() => skipBackward(10)}
            color="white"
            variant="plain"
            fontSize={['40px', '3.5rem', '4.5rem']}
        />
        <IconButton
            aria-label="Play/Pause"
            icon={isPlaying ? <FaPause /> : <FaPlay />}
            onClick={() => handlePlayPause()}
            color="white"
            variant="plain"
            fontSize={['40px', '3.5rem', '4.5rem']}
        />

        <IconButton
            aria-label="Avanzar 10 segundos"
            icon={<MdForward10 />}
            onClick={() => skipForward(10)}
            color="white"
            variant="plain"
            fontSize={['40px', '3.5rem', '4.5rem']}
        />
    </Flex>
)

export default PlayBackControl
