import { Icon } from '@chakra-ui/react'
import { FaStar } from 'react-icons/fa'
import React from 'react'

type StarClickHandler = (rating: number) => void

interface StarRatingProps {
    count: number
    onClick: StarClickHandler
}

const StarRating: React.FC<StarRatingProps> = ({ count, onClick }) => {
    return (
        <>
            {Array(5)
                .fill('')
                .map((_, i) => (
                    <Icon
                        as={FaStar}
                        key={i}
                        color={i < count ? 'yellow.400' : 'gray.600'}
                        cursor="pointer"
                        onClick={() => onClick(i + 1)}
                    />
                ))}
        </>
    )
}

export default StarRating
