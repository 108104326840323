import { useEffect, useMemo, useState } from 'react'
import {
    Box,
    Grid,
    GridItem,
    Heading,
    Image,
    Text,
    VStack,
    Center,
    Icon,
    IconButton
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { FaExclamationTriangle } from 'react-icons/fa'
import { TbArrowBigUpLineFilled } from 'react-icons/tb'
import { Movie } from '../../interfaces/movie'
import { cleanTitle } from '../../utils/cleanMovieTitle'
import MovieDetailModal from './MovieDetailModal'
import { genreTranslations } from '../../utils/genreTranslations'
import { strings } from '../../constants/strings'
import SpinnerCentered from '../Common/SpinnerCentered'
import NewBadge from '../Common/NewBadge'
import useScrollToTop from '../../hooks/useScrollToTop'
import {
    boxProps,
    centerNotFoundProps,
    gridImageProps,
    headingProps,
    headingTextProps,
    iconNotFoundProps,
    iconScrollToTopProps
} from '../../constants/styles/movieListStyles'
import { templateColumns } from '../../constants/styles/utils'

const MovieList: React.FC<{ shuffleTrigger: boolean; movieListData: any }> = ({
    shuffleTrigger,
    movieListData
}) => {
    const {
        currentMovieDetails,
        currentSubtitleText,
        currentVideoUrl,
        displayedMovies,
        handleOpenPlayer,
        isLoading,
        isLoadingPlayer,
        isModalReady,
        isOpen,
        moviePosters,
        movies,
        onClose,
        searchQuery,
        selectedGenre,
        selectedMovie,
        setDisplayedMovies,
        setIsModalReady
    } = movieListData

    const { isVisible, scrollToTop } = useScrollToTop()
    const [isInitializing, setIsInitializing] = useState(true)
    const filteredMovies = useMemo(() => {
        const allMovies = Object.keys(movies)
            .flatMap(year =>
                movies[year].map((movie: Movie) => ({
                    ...movie,
                    year
                }))
            )
            .reverse()

        const filteredMoviesByQuery = allMovies.filter(movie =>
            movie.title.toLowerCase().includes(searchQuery.toLowerCase())
        )

        const sortedMovies = filteredMoviesByQuery.sort((a, b) => {
            const dateA = new Date(a.lastModified)
            const dateB = new Date(b.lastModified)

            if (dateB > dateA) return 1
            if (dateB < dateA) return -1

            const yearA = a.year
            const yearB = b.year
            if (yearB !== yearA) return yearB.localeCompare(yearA)

            const titleA = a.title
            const titleB = b.title
            return titleA.localeCompare(titleB)
        })

        return sortedMovies
    }, [movies, searchQuery])

    const isNewMovie = (lastModified: string): boolean => {
        const oneWeekAgo = new Date()
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

        return new Date(lastModified) > oneWeekAgo
    }

    const shuffleMovies = (movies: Movie[]) => {
        return movies
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
    }

    useEffect(() => {
        if (shuffleTrigger) {
            setDisplayedMovies(shuffleMovies(filteredMovies))
        } else {
            setDisplayedMovies(filteredMovies)
        }
    }, [shuffleTrigger, setDisplayedMovies, filteredMovies])

    useEffect(() => {
        if (!isLoading && displayedMovies.length > 0) {
            setIsInitializing(false)
        }
    }, [isLoading, displayedMovies])

    return (
        <Box {...boxProps}>
            <Heading {...headingProps}>
                {selectedGenre && (
                    <>
                        {strings.moviesOf}{' '}
                        <Text {...headingTextProps}>
                            {genreTranslations[selectedGenre] || selectedGenre}
                        </Text>
                    </>
                )}
            </Heading>
            {isInitializing ? (
                <SpinnerCentered />
            ) : filteredMovies.length > 0 ? (
                <Grid templateColumns={templateColumns} gap={{ base: 4, md: 8 }}>
                    {displayedMovies.map((movie: any, index: any) => {
                        const fileName = movie.files?.[0]?.name || ''
                        const needsConversion = !fileName.endsWith('.mp4')
                        const isNew = isNewMovie(movie.lastModified)

                        return (
                            <GridItem
                                as={motion.div}
                                position="relative"
                                textAlign="center"
                                borderRadius={['10px', '20px']}
                                layout
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: 20 }}
                                whileHover={{
                                    scale: 1.05
                                }}
                                transition={{ duration: 0.3, delay: index * 0.05 } as any}
                                key={movie.title}
                                onClick={() =>
                                    !needsConversion &&
                                    handleOpenPlayer(
                                        movie.year,
                                        movie.title,
                                        movie.files[0],
                                        movie.subtitles?.[0]
                                    )
                                }
                                cursor={needsConversion ? 'not-allowed' : 'pointer'}
                            >
                                {isNew && <NewBadge />}
                                <VStack align="center">
                                    {isLoadingPlayer &&
                                        selectedMovie?.title === movie.title &&
                                        selectedMovie?.year === movie.year && <SpinnerCentered />}

                                    <Image
                                        {...gridImageProps}
                                        src={
                                            moviePosters[
                                                `${cleanTitle(movie.title)}_${movie.year}_poster`
                                            ] ||
                                            'https://via.placeholder.com/200x300?text=No+Poster'
                                        }
                                        alt={`${movie.title} poster`}
                                        opacity={
                                            (isLoadingPlayer &&
                                                selectedMovie?.title === movie.title &&
                                                selectedMovie?.year === movie.year) ||
                                            needsConversion
                                                ? 0.4
                                                : 1
                                        }
                                    />
                                    <Text fontSize="lg" fontWeight="regular">
                                        {movie.title.replace(/\s?\(\d+\)/, '')}
                                    </Text>
                                </VStack>
                            </GridItem>
                        )
                    })}
                </Grid>
            ) : (
                <Center {...centerNotFoundProps}>
                    <Icon {...iconNotFoundProps} as={FaExclamationTriangle} />
                    <Text fontSize="lg" fontWeight="bold">
                        No se encontró la película
                    </Text>
                    <Text fontSize="md">Intenta buscar con otro título.</Text>
                </Center>
            )}

            <MovieDetailModal
                isOpen={isOpen && isModalReady}
                onClose={() => {
                    onClose()
                    setIsModalReady(false)
                }}
                videoUrl={currentVideoUrl}
                subtitleText={currentSubtitleText}
                movieDetails={currentMovieDetails}
                onLoaded={() => setIsModalReady(true)}
            />

            {isVisible && (
                <IconButton
                    {...iconScrollToTopProps}
                    icon={<Icon as={TbArrowBigUpLineFilled} boxSize="1.5em" />}
                    onClick={scrollToTop}
                />
            )}
        </Box>
    )
}

export default MovieList
