import { BadgeProps, BoxProps, ModalBodyProps, ModalCloseButtonProps, ModalContentProps } from '@chakra-ui/react';

export const modalContentProps: ModalContentProps = {
    bg: 'gray.900',
    color: 'white',
    p: 4,
    maxW: ['95vw', '96vw', '95vw', '80vw'],
    maxH: ['95vh', '96vh', '90vh'],
    width: '100%',
    borderRadius: 'lg',
    overflow: 'hidden',
    transition: 'all 0.3s'
};

export const modalCloseButtonProps: ModalCloseButtonProps = {
    color: 'white',
    top: '10px',
    right: '10px',
    zIndex: '1'
};

export const modalBodyProps: ModalBodyProps = {
    display: 'flex',
    flexDirection: ['column', 'column', 'row'],
    p: 4,
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
    transition: 'all 0.3s',
    maxH: '85vh'
};

export const boxVideoProps: BoxProps = {
    flex: '3',
    mb: [4, 0],
    mr: [0, 6],
    width: '100%',
    position: 'relative'
};

export const badgeTitleProps: BadgeProps = {
    size: 'lg',
    mb: 4,
    fontSize: ['lg', '1.3rem'],
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    style: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2
    }
};

export const imageModalProps: BoxProps = {
    borderRadius: 'md',
    boxSize: ['150px', '200px', '250px'],
    objectFit: 'cover',
    boxShadow: 'lg'
};
