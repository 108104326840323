import { useEffect } from 'react'
import useSeriesStore, { Episode } from '../stores/seriesStore'
import { API_SERIES_URL } from '../api/urls'

const useSeriesDetail = () => {
    const isModalOpen = useSeriesStore(state => state.isModalOpen)
    const closeModal = useSeriesStore(state => state.closeModal)
    const selectedSeries = useSeriesStore(state => state.selectedSeries)
    const selectedSeason = useSeriesStore(state => state.selectedSeason)
    const videoUrl = useSeriesStore(state => state.videoUrl)
    const isLoadingEpisodes = useSeriesStore(state => state.isLoadingEpisodes)
    const setSelectedSeason = useSeriesStore(state => state.setSelectedSeason)
    const setVideoUrl = useSeriesStore(state => state.setVideoUrl)
    const fetchSeasonEpisodes = useSeriesStore(state => state.fetchSeasonEpisodes)
    const episodesData = useSeriesStore(state => state.episodesData)
    const setSubtitleUrl = useSeriesStore(state => state.setSubtitleUrl)
    const subtitleUrl = useSeriesStore(state => state.subtitleUrl)
    const selectedEpisodeTitle = useSeriesStore(state => state.selectedEpisodeTitle)
    const setSelectedEpisodeTitle = useSeriesStore(state => state.setSelectedEpisodeTitle)
    const selectedEpisode = useSeriesStore(state => state.selectedEpisode)
    const setSelectedEpisode = useSeriesStore(state => state.setSelectedEpisode)
    const selectedSeasonEpisode = useSeriesStore(state => state.selectedSeasonEpisode)
    const setSelectedSeasonEpisode = useSeriesStore(state => state.setSelectedSeasonEpisode)

    useEffect(() => {
        if (isModalOpen) {
            setSelectedSeason('')
            setVideoUrl(null)
            setSelectedEpisodeTitle(null)
            setSelectedSeasonEpisode(null)
            setSelectedEpisode(null)
        }
    }, [isModalOpen, setSelectedSeason, setVideoUrl])

    const handleSeasonChange = async (season: string) => {
        setSelectedSeason(season)

        if (!episodesData[season]) {
            await fetchSeasonEpisodes(selectedSeries!.title, season)
        }

        setSelectedEpisodeTitle(null)
        setSelectedSeasonEpisode(null)
        setSelectedEpisode(null)
    }

    const handleEpisodeSelect = (episode: Episode) => {
        if (episode.type === 'video') {
            setVideoUrl(
                `${API_SERIES_URL}/stream/${selectedSeries!.title}/${selectedSeason}/${
                    episode.filename
                }`
            )
            setSubtitleUrl(episode.subtitleText || null)
            setSelectedEpisodeTitle(episode.Title || 'Sin Título')
            setSelectedSeasonEpisode(episode.season || null)
            setSelectedEpisode(episode.episode || null)
        }
    }

    const resetVideo = () => {
        setVideoUrl(null)
        setSelectedEpisodeTitle(null)
        setSelectedSeasonEpisode(null)
        setSelectedEpisode(null)
    }

    return {
        isModalOpen,
        closeModal,
        selectedSeries,
        selectedSeason,
        videoUrl,
        isLoadingEpisodes,
        episodesData,
        subtitleUrl,
        selectedEpisodeTitle,
        selectedSeasonEpisode,
        selectedEpisode,
        handleSeasonChange,
        handleEpisodeSelect,
        resetVideo
    }
}

export default useSeriesDetail
