import axios from 'axios'
import { API_USER_URL } from '../api/urls'
import { UseToastOptions, ToastId } from '@chakra-ui/react'

type ToastFunction = (options?: UseToastOptions) => ToastId

/**
 * Logs in the user
 * @param {ToastFunction} toast - The toast function
 * @returns {Promise<void>} The promise
 */
export const logout = async (toast: ToastFunction): Promise<void> => {
    try {
        const token = localStorage.getItem('authToken')
        await axios.post(
            `${API_USER_URL}/logout`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        )

        localStorage.removeItem('authToken')

        toast({
            title: 'Logout successful.',
            status: 'success',
            duration: 3000,
            isClosable: true
        })
    } catch (error) {
        toast({
            title: 'Logout failed.',
            description: 'An error occurred while logging out.',
            status: 'error',
            duration: 3000,
            isClosable: true
        })
    }
}
