import React from 'react'
import {
    InputGroup,
    InputLeftElement,
    Input,
    InputRightElement,
    IconButton
} from '@chakra-ui/react'
import { FaSearch, FaTimes } from 'react-icons/fa'

interface SearchBarProps {
    inputGroupProps?: any
    inputValue: string
    clearInput: () => void
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    isDisabled?: boolean
}

const SearchBar: React.FC<SearchBarProps> = ({
    inputGroupProps,
    inputValue,
    clearInput,
    handleInputChange,
    isDisabled = false
}) => {
    return (
        <InputGroup {...inputGroupProps}>
            <InputLeftElement pointerEvents="none">
                <FaSearch color="gray.300" />
            </InputLeftElement>
            <Input
                placeholder="Buscar por título o año"
                variant="filled"
                bg={isDisabled ? 'gray.600' : 'gray.700'}
                _hover={{ bg: isDisabled ? 'gray.600' : 'gray.600' }}
                _focus={{ bg: isDisabled ? 'gray.600' : 'gray.600' }}
                color="white"
                value={inputValue}
                onChange={handleInputChange}
                isDisabled={isDisabled}
            />
            {inputValue && (
                <InputRightElement>
                    <IconButton
                        icon={<FaTimes />}
                        size="md"
                        color="gray.300"
                        bg="transparent"
                        _hover={{ color: 'red.400' }}
                        aria-label="Clear search"
                        onClick={clearInput}
                        isDisabled={isDisabled}
                    />
                </InputRightElement>
            )}
        </InputGroup>
    )
}

export default SearchBar
