import axios from 'axios'
import { API_EXTERNAL_URL, API_SERIES_PROGRESS_URL, API_SERIES_URL } from './urls'
import { Episode } from '../interfaces/series'
import { VideoProgressResponse, VideoDetails } from '../interfaces/global'

/**
 * Fetches series data from the external API.
 * @param {string} title The title of the series.
 * @returns {Promise<VideoDetails>} The series data.
 */
export const fetchSeriesDataApi = async (title: string): Promise<VideoDetails | null> => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: {
                title: title
            }
        })
        return response.data
    } catch (error: any) {
        if (error.response && error.response.status === 404) {
            console.warn(`Series not found in external API: ${title}`)
        } else {
            console.error(`Error fetching data from external API for ${title}:`, error)
        }
        return null
    }
}

/**
 * Fetches season series data from the external API.
 * @param {string} title The title of the series.
 * @returns {Promise<Episode[]>} The series data.
 */
export const fetchSeasonEpisodesBasicApi = async (
    title: string,
    season: string
): Promise<Episode[]> => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: {
                title,
                season: season.split(' ')[1]
            }
        })
        return response.data.Episodes || []
    } catch (error: any) {
        console.error(`Error fetching season episodes for ${title}, Season ${season}:`, error)
        return []
    }
}

/**
 * Fetches episode data from the external API.
 * @param {string} title The title of the series.
 * @param {string} season The season of the series.
 * @param {string} episode The episode of the series.
 * @returns {Promise<VideoDetails>} The episode data.
 */
export const fetchEpisodeDataApi = async (
    title: string,
    season: string,
    episode: string
): Promise<VideoDetails | null> => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: {
                title,
                season: season.split(' ')[1],
                episode
            }
        })
        return response.data
    } catch (error: any) {
        console.error(`Error fetching episode data from external API for ${title}:`, error)
        return null
    }
}

/**
 * Fetches the latest timestamp for series data from the local API.
 * @returns {Promise<number>} The latest timestamp.
 */
export const fetchSeriesLatestTimestamp = async (): Promise<number> => {
    try {
        const response = await axios.get(`${API_SERIES_URL}/latest-timestamp`)
        return response.data.latestTimestamp
    } catch (error) {
        console.error('Error fetching latest series timestamp:', error)
        return 0
    }
}

/**
 * Fetches series progress from the local API.
 * @param {string} username - The username
 * @param {string} title - The title of the series
 * @param {number} season - The season of the series
 * @param {number} episode - The episode of the series
 * @returns {Promise<VideoProgressResponse>} The series progress
 */
export const fetchSeriesProgress = async (
    username: string | null,
    title: string,
    season: number,
    episode: number
): Promise<VideoProgressResponse> => {
    try {
        const response = await axios.get(`${API_SERIES_PROGRESS_URL}/get`, {
            params: { username, title, season, episode }
        })
        return { progress: response.data.progress, isMuted: response.data.isMuted }
    } catch (error) {
        console.error(
            `Error fetching series progress for ${title}, Season ${season}, Episode ${episode}:`,
            error
        )
        return { progress: 0, isMuted: false }
    }
}

/**
 * Saves series progress to the local API.
 * @param {string} username - The username
 * @param {string} title - The title of the series
 * @param {number} season - The season of the series
 * @param {number} episode - The episode of the series
 * @param {number} progress - The progress of the series
 * @param {boolean} isMuted - Whether the series is muted
 * @returns {Promise<void>}
 */
export const saveSeriesProgress = async (
    username: string | null,
    title: string,
    season: number,
    episode: number,
    progress: number,
    isMuted: boolean
): Promise<void> => {
    try {
        await axios.post(`${API_SERIES_PROGRESS_URL}/save`, {
            username,
            title,
            season,
            episode,
            progress,
            isMuted
        })
    } catch (error) {
        console.error(
            `Error saving series progress for ${title}, Season ${season}, Episode ${episode}:`,
            error
        )
    }
}
