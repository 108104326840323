import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import useFilterStore from '../stores/filterStore'
import useAuthStore from '../stores/authStore'
import { YEARS } from '../constants/strings'
import debounce from 'lodash/debounce'

const useHeader = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const toast = useToast()

    const genres = useFilterStore(state => state.genres)
    const inputValue = useFilterStore(state => state.inputValue)
    const handleLogout = useAuthStore(state => state.handleLogout)
    const setSearchQuery = useFilterStore(state => state.setSearchQuery)
    const setSelectedGenre = useFilterStore(state => state.setSelectedGenre)
    const setSelectedYear = useFilterStore(state => state.setSelectedYear)
    const setInputValue = useFilterStore(state => state.setInputValue)
    const uniqueGenres = Array.from(new Set(genres))
    const username = useAuthStore(state => state.username)

    const [tempInputValue, setTempInputValue] = useState(inputValue)

    const updateSearchQuery = debounce(value => {
        if (value === '') {
            setSearchQuery('')
            setSelectedYear(null)
        } else if (!isNaN(Number(value)) && value.length === 4) {
            setSelectedYear(value)
            setSearchQuery('')
        } else {
            setSelectedYear(null)
            setSearchQuery(value)
        }
    }, 300)

    useEffect(() => {
        updateSearchQuery(tempInputValue)
    }, [tempInputValue, updateSearchQuery])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTempInputValue(e.target.value)
        setInputValue(e.target.value)
    }

    const clearInput = () => {
        setTempInputValue('')
        setInputValue('')
        setSearchQuery('')
        setSelectedYear(null)
    }

    const handleNavigation = (path: string) => {
        if (location.pathname !== path) {
            navigate(path)
        }
    }

    return {
        location,
        toast,
        navigate,
        handleNavigation,
        uniqueGenres,
        setSelectedGenre,
        setSelectedYear,
        YEARS,
        username,
        handleLogout,
        inputValue: tempInputValue,
        handleInputChange,
        clearInput
    }
}

export default useHeader
