import { create, StoreApi, UseBoundStore } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { localStorageAdapter } from '../utils/localStorageAdapter'
import { GenericState } from '../interfaces/global'

interface FilterState {
    genres: string[]
    selectedGenre: string | null
    selectedYear: string | null
    searchQuery: string
    inputValue: string
    setGenres: (genres: string[]) => void
    setInputValue: (inputValue: string) => void
    setSelectedGenre: (genre: string | null) => void
    setSelectedYear: (year: string | null) => void
    setSearchQuery: (query: string) => void
}

export const createStoreWithSelectors = <T extends GenericState>(
    store: UseBoundStore<StoreApi<T>>
): (<K extends keyof T>(keys: K[]) => Pick<T, K>) => {
    const useStore: <K extends keyof T>(keys: K[]) => Pick<T, K> = <K extends keyof T>(
        keys: K[]
    ) => {
        const selectedState = store(
            state =>
                keys.reduce((acc, cur) => {
                    acc[cur] = state[cur]
                    return acc
                }, {} as T) as Pick<T, K>
        )
        return selectedState
    }

    return useStore
}

const useFilterStore = create<FilterState>()(
    devtools(
        persist(
            set => ({
                genres: [],
                selectedGenre: null,
                selectedYear: null,
                searchQuery: '',
                inputValue: '',

                setGenres: (genres: string[]) =>
                    set(() => ({
                        genres: genres
                    })),
                setInputValue: (inputValue: string) => set({ inputValue }),
                setSelectedGenre: (selectedGenre: string | null) => set({ selectedGenre }),
                setSelectedYear: (selectedYear: string | null) => set({ selectedYear }),
                setSearchQuery: (query: string) => set({ searchQuery: query })
            }),
            {
                name: 'filter-store',
                storage: localStorageAdapter
            }
        )
    )
)

export default useFilterStore
