import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { API_MOVIE_RATINGS_URL } from '../api/urls'

interface MovieRatingResponse {
    averageRating: number
    totalRatings: number
    userRating: number | null
}

interface UserRatingResponse {
    newAverage: number
}

interface UseMovieRatingReturn {
    averageRating: number
    userRating: number | null
    rateMovie: (rating: number) => Promise<void>
    loading: boolean
}

const useMovieRating = (
    title: string | undefined,
    year: string | undefined
): UseMovieRatingReturn => {
    const [averageRating, setAverageRating] = useState<number>(0)
    const [userRating, setUserRating] = useState<number | null>(null)
    const [loading, setLoading] = useState<boolean>(false)

    const fetchMovieRating = useCallback(async () => {
        if (!title || !year) return
        try {
            setLoading(true)
            const response = await axios.get<MovieRatingResponse>(
                `${API_MOVIE_RATINGS_URL}/user-rating/${title}/${year}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                }
            )
            setAverageRating(Number(response.data.averageRating) ?? 0)
            setUserRating(response.data.userRating)
        } catch (error) {
            console.error('Error fetching movie rating:', error)
        } finally {
            setLoading(false)
        }
    }, [title, year])

    useEffect(() => {
        fetchMovieRating()
    }, [fetchMovieRating])

    const rateMovie = async (rating: number): Promise<void> => {
        if (!title || !year) return
        try {
            setUserRating(rating)
            const response = await axios.post<UserRatingResponse>(
                `${API_MOVIE_RATINGS_URL}/rate`,
                {
                    title,
                    year,
                    rating
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                }
            )
            setAverageRating(Number(response.data.newAverage) ?? rating)
        } catch (error) {
            console.error('Error rating movie:', error)
        }
    }

    return {
        averageRating,
        userRating,
        rateMovie,
        loading
    }
}

export default useMovieRating
