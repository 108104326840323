import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { logout } from '../api/authService'
import { localStorageAdapter } from '../utils/localStorageAdapter'

interface AuthState {
    isAuthenticated: boolean
    isLoading: boolean
    isModalOpen: boolean
    isInactive: boolean
    username: string
    handleLoginSuccess: (token: string, username: string) => void
    handleLogout: (toast: any) => Promise<void>
    setLoading: (loading: boolean) => void
    setModalOpen: (isOpen: boolean) => void
    setInactive: (inactive: boolean) => void
    handleContinueSession: () => void
    setUsername: (username: string) => void
}

const useAuthStore = create<AuthState>()(
    devtools(
        persist(
            set => ({
                isAuthenticated: false,
                isLoading: true,
                isModalOpen: false,
                isInactive: false,
                username: '',

                handleLoginSuccess: (token: string, username: string) => {
                    localStorage.setItem('authToken', token)
                    localStorage.setItem('username', username)
                    set({ isAuthenticated: true, username })
                },
                handleLogout: async toast => {
                    await logout(toast)
                    localStorage.removeItem('authToken')
                    localStorage.removeItem('username')
                    set({ isAuthenticated: false, isModalOpen: false, username: '' })
                },

                setLoading: loading => set({ isLoading: loading }),
                setModalOpen: isOpen => set({ isModalOpen: isOpen }),
                setInactive: inactive => set({ isInactive: inactive }),
                setUsername: (username: string) => set({ username }),

                handleContinueSession: () => set({ isModalOpen: false, isInactive: false })
            }),
            {
                name: 'auth-storage',
                storage: localStorageAdapter
            }
        )
    )
)

export default useAuthStore
