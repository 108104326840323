import { ModalHeader, Text } from '@chakra-ui/react'

interface SeriesHeaderProps {
    title?: string | null
    episodeTitle?: string | null
    seasonEpisode?: string | null
    episode?: string | null
}

const SeriesHeader: React.FC<SeriesHeaderProps> = ({
    title,
    episodeTitle,
    seasonEpisode,
    episode
}) => (
    <ModalHeader>
        {title}
        {episodeTitle && (
            <Text fontSize="md" color="gray.400" mt={1}>
                {seasonEpisode} {episode} - {episodeTitle}
            </Text>
        )}
    </ModalHeader>
)

export default SeriesHeader
