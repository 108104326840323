import { Flex, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { FaBars } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import { strings } from '../../constants/strings'

interface HamburguerMenuProps {
    handleNavigation: (path: string) => void
    handleLogout: (toast: any) => void
    toast: any
}

const HamburguerMenu: React.FC<HamburguerMenuProps> = ({
    handleNavigation,
    handleLogout,
    toast
}) => {
    const location = useLocation()

    return (
        <Flex display={{ base: 'flex', md: 'none' }} align="center">
            <Menu>
                <MenuButton
                    as={IconButton}
                    icon={<FaBars />}
                    variant="ghost"
                    color="white"
                    aria-label="Options"
                />
                <MenuList bg="gray.700" color="white" borderColor="gray.600">
                    <MenuItem
                        onClick={() => handleNavigation('/')}
                        _hover={{ bg: 'gray.600' }}
                        bg={location.pathname === '/' ? 'gray.600' : 'gray.700'}
                    >
                        {strings.movies}
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleNavigation('/series')}
                        _hover={{ bg: 'gray.600' }}
                        bg={location.pathname === '/series' ? 'gray.600' : 'gray.700'}
                    >
                        {strings.series}
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleLogout(toast)}
                        _hover={{ bg: 'gray.600' }}
                        bg="gray.700"
                    >
                        {strings.logout}
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    )
}

export default HamburguerMenu
