import axios from 'axios'
import { API_EXTERNAL_URL } from './urls'

/**
 * Fetches data from the OMDb API.
 * @param {string} year  The year of the movie.
 * @param {string} title The title of the movie.
 * @returns {Promise<VideoDetails>} The movie data.
 */
export const fetchOmdb = async (year: string, title: string) => {
    try {
        const response = await axios.get(`${API_EXTERNAL_URL}/movie`, {
            params: { year, title }
        })

        return response.data
    } catch (error) {
        console.error(`Error fetching poster for ${title}:`, error)
        return null
    }
}

/**
 * Translates text to Spanish.
 * @param {string} text The text to translate.
 * @returns {Promise<string>} The translated text.
 */
export const translateToSpanish = async (text: string) => {
    try {
        const response = await axios.post(`${API_EXTERNAL_URL}/translate`, {
            text
        })

        return response.data.translatedText
    } catch (error) {
        console.error(`Error translating text:`, error)
    }
}
