import { HStack, IconButton } from '@chakra-ui/react'
import { FaClosedCaptioning, FaExpand, FaStepBackward, FaStepForward } from 'react-icons/fa'

interface AditionalControlsProps {
    areSubtitlesVisible: boolean
    handleFullScreen: () => void
    subtitleUrl: string | null
    toggleSubtitles: () => void
    goToPreviousEpisode?: () => void
    goToNextEpisode?: () => void
    hasPreviousEpisode?: boolean
    hasNextEpisode?: boolean
    videoType: 'movie' | 'series'
}

const AditionalControls: React.FC<AditionalControlsProps> = ({
    areSubtitlesVisible,
    handleFullScreen,
    subtitleUrl,
    toggleSubtitles,
    goToPreviousEpisode,
    goToNextEpisode,
    hasPreviousEpisode,
    hasNextEpisode,
    videoType
}) => (
    <HStack spacing={2} ml="auto">
        {/* Previous Episode */}
        {videoType === 'series' && (
            <>
                <IconButton
                    aria-label="Previous Episode"
                    icon={<FaStepBackward />}
                    onClick={goToPreviousEpisode}
                    isDisabled={!hasPreviousEpisode}
                    color="white"
                    variant="ghost"
                    fontSize="20px"
                />

                <IconButton
                    aria-label="Next Episode"
                    icon={<FaStepForward />}
                    onClick={goToNextEpisode}
                    isDisabled={!hasNextEpisode}
                    color="white"
                    variant="ghost"
                    fontSize="20px"
                />
            </>
        )}

        {/* Subtitles */}
        {subtitleUrl && (
            <IconButton
                aria-label="Toggle Subtitles"
                icon={<FaClosedCaptioning />}
                onClick={toggleSubtitles}
                color={areSubtitlesVisible ? 'red.500' : 'white'}
                variant="ghost"
                fontSize="20px"
            />
        )}

        {/* Fullscreen */}
        <IconButton
            aria-label="Fullscreen"
            icon={<FaExpand />}
            onClick={() => {
                handleFullScreen()
                if (
                    typeof window !== 'undefined' &&
                    window.screen.orientation &&
                    (window.screen.orientation as any).lock
                ) {
                    ;(window.screen.orientation as any).lock('landscape').catch(() => {})
                }
            }}
            color="white"
            variant="ghost"
            fontSize="20px"
        />
    </HStack>
)

export default AditionalControls
