import { isLocalHost } from '../utils/isLocalHost'

export const API_MOVIES_URL = isLocalHost()
    ? 'http://localhost:4000/api/movies'
    : 'https://maimovies-be.onrender.com/api/movies'

export const API_MOVIES_DATA_URL = isLocalHost()
    ? 'http://localhost:8000/api/movies-data'
    : 'https://api-movies-data.onrender.com/api/movies-data'

export const API_MOVIE_PROGRESS_URL = isLocalHost()
    ? 'http://localhost:4000/api/movie-progress'
    : 'https://maimovies-be.onrender.com/api/movie-progress'

export const API_USER_URL = isLocalHost()
    ? 'http://localhost:4000/api/user'
    : 'https://maimovies-be.onrender.com/api/user'

export const API_EXTERNAL_URL = isLocalHost()
    ? 'http://localhost:4000/api/external'
    : 'https://maimovies-be.onrender.com/api/external'

export const API_MOVIE_RATINGS_URL = isLocalHost()
    ? 'http://localhost:4000/api/movie-ratings'
    : 'https://maimovies-be.onrender.com/api/movie-ratings'

export const API_SERIES_URL = isLocalHost()
    ? 'http://localhost:4000/api/series'
    : 'https://maimovies-be.onrender.com/api/series'

export const API_SERIES_PROGRESS_URL = isLocalHost()
    ? 'http://localhost:4000/api/series-progress'
    : 'https://maimovies-be.onrender.com/api/series-progress'

export const API_THUMBNAILS_URL = isLocalHost()
    ? 'http://localhost:4000/api/thumbnails'
    : 'https://maimovies-be.onrender.com/api/thumbnails'
